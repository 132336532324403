jQuery(function ($) {
  $(".navbar-toggle").sidr({
    name: "mainMenu",
    source: ".site-header nav",
    side: "right",
    onOpen: function () {
      setTimeout(function () {
        canClick = true;
      }, 100);
    },
    onClose: function () {
      canClick = false;
    },
  });

  $(document).bind("click", function () {
    $.sidr("close", "mainMenu");
  });

  $(".header-search.closed .search-toggle").click(function (e) {
    e.preventDefault();

    $(".header-search").removeClass("closed").addClass("open");
    $(".header-search input").focus();
  });
  $(".header-search .search-close").click(function (e) {
    e.preventDefault();

    $(".header-search").removeClass("open").addClass("closed");
  });

  $("a>img[alt]:not([title])").each(function () {
    const title = $(this).attr("alt");
    $(this).attr("title", title);
  });

  if ($("body.historia").length > 0) {
    $(".historiikki_slider").show();

    $(".historiikki_slider ul").cycle({
      slides: "> li",
      timeout: 5000,
      next: ".historiikki_slider",
      log: false,
    });
  }

  if ($("body.ik-team").length > 0) {
    $(".anchor-link-list a").each(function (e) {
      const target = $(this).attr("href");

      if ($(target).length < 1) {
        $(this).addClass("disabled");
      }
    });
  }
  if ($("body.page-sfim-2014").length > 0) {
    $(".toggleLanguage").click(function (e) {
      e.preventDefault();
      if ($(this).hasClass("toggleFI")) {
        $(".sv_FI").hide();
        $(".fi_FI").show();
      }
      if ($(this).hasClass("toggleSV")) {
        $(".fi_FI").hide();
        $(".sv_FI").show();
      }
    });

    $(".main h1").html(
      [
        $("body.sfim-2014 h1").text().slice(0, 35),
        "<br>",
        $(".main h1").text().slice(35),
      ].join("")
    );
  }

  if ($("body.page-arvokilpailumenestys").length > 0) {
    $(".content table.mitalit_henkilo tr, .content table.mitalit tr").each(
      function (index, obj) {
        index = 0;
        $(this)
          .find("td")
          .each(function (index, obj) {
            index++;
            $(this).addClass("col-" + index);
          });
      }
    );
  }
  if ($("body.page-lenkkireitit").length > 0) {
    const targetDiv = $(".kartta_talvilenkit").attr("id");

    const kartta = new google.maps.StyledMapType([], {
      name: "Google",
    });
    const satelliitti = new google.maps.StyledMapType([], {
      name: "Satelliitti",
    });
    const OSM = new google.maps.ImageMapType({
      getTileUrl: function (coord, zoom) {
        return (
          "http://tile.thunderforest.com/cycle/" +
          zoom +
          "/" +
          coord.x +
          "/" +
          coord.y +
          ".png"
        );
      },
      tileSize: new google.maps.Size(256, 256),
      name: "OSM Cycle",
      maxZoom: 18,
    });
    const maasto = new google.maps.ImageMapType({
      getTileUrl: function (coord, zoom) {
        return (
          "http://tiles.kartat.kapsi.fi/peruskartta/" +
          zoom +
          "/" +
          coord.x +
          "/" +
          coord.y +
          ".jpg"
        );
      },
      tileSize: new google.maps.Size(256, 256),
      name: "Maasto",
      maxZoom: 17,
      minZoom: 11,
    });
    const ilma = new google.maps.ImageMapType({
      getTileUrl: function (coord, zoom) {
        return (
          "http://tiles.kartat.kapsi.fi/ortokuva/" +
          zoom +
          "/" +
          coord.x +
          "/" +
          coord.y +
          ".jpg"
        );
      },
      tileSize: new google.maps.Size(256, 256),
      name: "Ilmakuva",
      maxZoom: 18,
      minZoom: 13,
    });
    const blank = new google.maps.StyledMapType(
      [
        {
          stylers: [{ color: "#222222" }],
        },
      ],
      {
        name: "Tyhjä",
      }
    );
    const fullScreenControlDiv = document.createElement("div");
    fullScreenControlDiv.index = 1;

    const center = new google.maps.LatLng(60.3, 24.65);

    const Options = {
      zoom: 9,
      minZoom: 8,
      center: center,
      mapTypeId: "kartta",
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        mapTypeIds: ["kartta", "maasto", "osm", "ilma", "satellite", "blank"],
        position: google.maps.ControlPosition.TOP_RIGHT,
      },
    };
    map = new google.maps.Map(document.getElementById(targetDiv), Options);

    map.mapTypes.set("kartta", kartta);
    map.mapTypes.set("satellite", satelliitti);
    map.mapTypes.set("osm", OSM);
    map.mapTypes.set("maasto", maasto);
    map.mapTypes.set("ilma", ilma);
    map.mapTypes.set("blank", blank);

    //apply click-handler
    google.maps.event.addListener(map, "click", function (e) {
      if (this.mapTypeId === "click_map") {
        document.getElementById("latlng").value = e.latLng.toString();
      }
    });
    google.maps.event.addListener(map, "maptypeid_changed", function () {
      if (this.mapTypeId === "kartta") {
        this.setMapTypeId(google.maps.MapTypeId.ROADMAP);
      }
      if (this.mapTypeId === "satellite") {
        this.setMapTypeId(google.maps.MapTypeId.HYBRID);
      }
    });

    layers = [];
    const full =
      location.protocol +
      "//" +
      location.hostname +
      (location.port ? ":" + location.port : "");
    $(".talvilenkit_date").each(function (i, obj) {
      if (location.hostname === "ik-32.org") {
        layers[i] = full + "/wordpress/kml/" + $(this).data("id") + ".kml";
      }
      if (location.hostname === "localhost") {
        layers[i] = full + "/IK-32/kml/" + $(this).data("id") + ".kml";
      }
    });

    parser = new geoXML3.parser({
      map: map,
      zoom: false,
    });
    parser.parse(layers);

    $("label.talvilenkit_date input").click(function () {
      let i;
      const parentElement = $(this).parent();

      if ($(this).parent().parent().index() > 0) {
        const parentIndex = $(this).parent().parent().index();
        const elementsPerCol = $(this)
          .parent()
          .parent()
          .parent()
          .children()
          .eq(0)
          .children().length;
        i = $(this).parent().index() + parentIndex * elementsPerCol;
      } else {
        i = $(this).parent().index();
      }

      if (parentElement.data("visible") === 1) {
        parser.hideDocument(parser.docs[i]);
        parentElement.data("visible", 0);
      } else {
        parser.showDocument(parser.docs[i]);
        parentElement.data("visible", 1);
      }
    });
    let allSelected = true;
    $(".selectAll input").click(function () {
      if (allSelected) {
        allSelected = false;
        $("label.talvilenkit_date input").prop("checked", false);
        jQuery.each(layers, function (i, obj) {
          parser.hideDocument(parser.docs[i]);
        });
        $(".talvilenkit_date").each(function (i, obj) {
          $(this).data("visible", 0);
        });
      } else {
        allSelected = true;
        $("label.talvilenkit_date input").prop("checked", true);
        jQuery.each(layers, function (i, obj) {
          parser.showDocument(parser.docs[i]);
        });
        $(".talvilenkit_date").each(function (i, obj) {
          $(this).data("visible", 1);
        });
      }
    });
  }
  if ($("body.yhteystiedot").length > 0) {
    const center = new google.maps.LatLng(60.21, 24.68);
    const Options = {
      zoom: 11,
      minZoom: 10,
      maxZoom: 15,
      center: center,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
    const map = new google.maps.Map(
      document.getElementById("kartta_yhteystiedot"),
      Options
    );

    const kerhohuoneSisalto =
      "<h4>IK-32 kerhohuone</h4>" + "Vanha Lagstadintie 5<br>02770 Espoo";

    const kerhohuoneBoksi = new google.maps.InfoWindow({
      content: kerhohuoneSisalto,
    });

    const kerhohuonePosition = new google.maps.LatLng(60.207251, 24.652621);
    const kerhohuoneMarker = new google.maps.Marker({
      position: kerhohuonePosition,
      map: map,
      title: "IK-32 kerhohuone",
    });

    const postiSisalto =
      "<h4>IK-32 postiosoite</h4>" + "Harjuviita 14 D 36<br>02110 Espoo";

    const postiBoksi = new google.maps.InfoWindow({
      content: postiSisalto,
    });

    const postiPosition = new google.maps.LatLng(60.1760755, 24.8239023);
    const postiMarker = new google.maps.Marker({
      position: postiPosition,
      map: map,
      title: "IK-32 postiosoite",
    });

    google.maps.event.addListener(kerhohuoneMarker, "click", function () {
      kerhohuoneBoksi.open(map, kerhohuoneMarker);
    });
    google.maps.event.addListener(postiMarker, "click", function () {
      postiBoksi.open(map, postiMarker);
    });
  }
});
